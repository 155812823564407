import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../css/landing.css";
import live from "../../assets/images/live.png";
import whatsapp from "../../assets/images/whatsapp.png";
import Downloadbutton from "../Components/Downloadbutton";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [userAllData, setUserAllData] = useState();
  const [commission, setcommission] = useState();
  const [refer, setrefer] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [blockMessage, setBlockMessage] = useState("");
  const [userBlock, setUserBlock] = useState(null);
  const [profile, setProfile] = useState();
  const [kycverify, setKycVerify] = useState();
  const [msg, setmsg] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setcommission(data.commission);
    setrefer(data.refer);
    setmsg(data.msg);

    return setWebsiteSettings(data);
  };

  const KycData = async () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setKycVerify(res.data.verified);
      })
      .catch((e) => { });
  };

  useEffect(() => {
    KycData();
  }, []);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.get(baseUrl + `me`, { headers });
      setUserAllData(res.data);
      const user = res.data;

      let blockValue =
        user.Wallet_balance -
        (user.wonAmount +
          user.totalDeposit +
          user.totalBonus -
          (user.loseAmount +
            user.totalWithdrawl +
            user.withdraw_holdbalance +
            user.hold_balance +
            user.totalPenalty) +
          user.referral_earning);

          if (blockValue !== 0 || user.hold_balance < 0) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please Contact Support Number 8852935438",
              timer: 4000,
              timerProgressBar: true, 
              allowOutsideClick: false,
            }).then(() => {
              localStorage.removeItem("token");
              window.location.reload();
            });
          }
    } catch (e) {

      if (e.response?.status === 401) {
        localStorage.removeItem("token");
      }
    }
  };

  useEffect(() => {
    role();
    fetchData();
  }, []);


  return (
    <>
      <div></div>

      {userBlock > 0 ? <div>{blockMessage}</div> : <div></div>}

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "35px" }}>
          <div className="header_top_message">
            <span>Commission:5% ◉ Referral: 2% For All Games</span>
          </div>
          <div className="header_top_message">
            <span>{msg}</span>
          </div>

          <div>
            {kycverify && kycverify === `unverified` ? (
              <Link to="Kyc2">
                <div className="alert alert-danger mt-3">
                  Please Complete your KYC
                </div>
              </Link>
            ) : kycverify && kycverify.verified === "pending" ? (
              <div className="alert alert-danger mt-3">KYC is in Process</div>
            ) : null}
          </div>

          <section className="games-section p-3">
            {/* <div className="downloadButton">
            <Downloadbutton />
          </div> */}
            <div className="d-flex align-items-center games-section-title"></div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
                  <span className="blink live text-danger">
                    {/* ◉ <img src={live} alt="Live" width={"30px"} /> */}◉ Ludo
                    Classic Only (LIVE)
                  </span>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/Ludo1.png"
                      }
                      // src="https://ik.imagekit.io/wnm4oiopu/dm6rPjl.png?updatedAt=1714425974021"
                      alt=""
                    />
                  </picture>
                </Link>
                <Link className="gameCard-container">
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/Ludo2.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>

                <Link className="gameCard-container">
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/snake.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>

                <Link className="gameCard-container">
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={process.env.PUBLIC_URL + "/Images/Homepage/card.png"}
                      alt=""
                    />
                  </picture>
                </Link>
              </div>
            </div>
            <h4 className="text-center text-dark popins mt-3">
              “ALL THE BEST FOR GAME’’
            </h4>
            <hr></hr>
          </section>
          <div class="ludowhatsapp">
            <div className="support_samll_card_btn_type whatsap_btn">
              <a href="https://wa.me/+918852935438?text=hello%20admin%20i%20need%20help" target="_blank">
                <img width="50px" src={whatsapp} alt="" />
              </a>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
