import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "../css/layout.css";
import "../css/login.css";

import loginss from "./ss.png";
import DeviceDetector from "device-detector-js";
import { Address4 } from "ip-address";
import CryptoJS from "crypto-js";

export default function Login() {
  const history = useHistory();
  const [Phone, setPhone] = useState();
  const [twofactor_code, settwofactor_code] = useState();
  const [otp, setOtp] = useState(false);
  const [secretCode, setSecretCode] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [time, setTime] = useState(new Date());
  const [referral, setReferral] = useState(
    useLocation().pathname.split("/")[2]
  );

  useEffect(() => {
    // Get IP address
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        const address = new Address4(ip);
        setIpAddress(address.address);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    setDeviceName(device.device.model || "Unknown Device");
  }, []);


  const encryptData = (data, secretKey) => {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    return encryptedData;
};

const generateHMAC = (data, secretKey) => {
  return CryptoJS.HmacSHA256(data, secretKey).toString(CryptoJS.enc.Base64);
};


  const handleClick = async (e) => {
    let secretKey = process.env.REACT_APP_SECRET_KEY;
    let newTime = time.toString(); 
    e.preventDefault();
    setIsButtonDisabled(true);
    const PhoneTelNum = Phone;
    if (!PhoneTelNum) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone",
      });
      setIsButtonDisabled(false);
    } else if (Phone.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a correct phone number",
      });
      setIsButtonDisabled(false);
    } else {
      try {
        const generateRandomAlphanumeric = (length) => {
          const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
          let result = "";
          const charactersLength = characters.length;
          for (let i = 0; i < length; i++) {
            result += characters.charAt(
              Math.floor(Math.random() * charactersLength)
            );
          }
          return result;
        };

        // const data = JSON.stringify({
        //   PhoneTelNum,
        //   referral,
        //   ipAddress,
        //   deviceName,
        // });

        const requestData = {
          PhoneTelNum,
          referral,
          ipAddress,
          deviceName,
          newTime,
      };

      const encryptedRequest = encryptData(requestData, secretKey);
      const hmac = generateHMAC(encryptedRequest, newTime);
      const dataToSend = { encryptedData: encryptedRequest, hmac };
       
      const response = await axios.post(
        "https://apifrontseed.khelohub.com/user-login-send-tsats",
        dataToSend,
        {
            headers: {
                'Content-Type': 'application/json', 
            },
        }
    );

        const { status, msg, secret } = response.data;
        if (status === 101) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: msg,
          });
          setIsButtonDisabled(false);
        } else if (status === 200) {
         
          setOtp(true);
          setSecretCode(secret);
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
        console.error(e);
        setIsButtonDisabled(false);
      }
    }
  };

  const varifyOtp = async (e) => {
    // debugger;
    e.preventDefault();
    console.log("verify otp sumbut req");
    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else {
      await axios
        .post(`https://apifrontseed.khelohub.com/login/finish`, {
          Phone,
          twofactor_code,
          referral,
          secretCode,
        })
        .then((respone) => {
          if (respone.data.status == 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status == 200) {
            const token = respone.data.token;
            localStorage.setItem("token", token);
            window.location.reload(true);
            setTimeout(function () {
              history.push("/Games");
            }, 1000);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };

  const setError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid Number",
      confirmation: true,
    });
  };
  return (
    <>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <div className="main-area bg-dark">
          <div style={{ overflowY: "hidden" }}>
            <div className="splash-overlay" />
            <div className="splash-screen animate__bounce infinite ">
              <figure>
                <img width="100%" src={loginss} alt="" />
              </figure>
            </div>

            <div
              className="position-absolute center-xy mx-5 "
              style={{
                top: "20%",
                width: "84%",
                height: "325px",
                zIndex: 9,
              }}
            >
              <div class="logodesign">
              <img src="/Images/Homepage/logo.png" alt="logo" />
              </div>
              
              
             <div class="loginformdesign">
             <div
                className="sign-up-title text-dark mb-2"
              >
                Sign in or sign up
              </div>
              <div
                className="d-flex center-xy text-uppercase font-15 loginfiller"
               
              >
                <div
                  className="input-group mb-2 bg-white"
                  style={{ transition: "top 0.5s ease 0s", top: "4px" }}
                >
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text counterycode"
                    
                    >
                      +91
                    </div>
                  </div>
                  <input
                    className="form-control-login bg-white"
                    name="mobile"
                    type="tel"
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      if (e.target.value.length > 10) {
                        setError(true);
                      }
                    }}
                    style={{
                      transition: "all 3s ease-out 0s",
                      letterSpacing: "1px",
                    }}
                  />
                </div>
              </div>
             
              {otp && (
                <div
                  className="d-flex center-xy text-uppercase font-15 loginfiller mt-2" >
                  <div
                    className="input-group mb-2"
                    style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                  >
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text counterycode"
                      
                      >
                        OTP
                      </div>
                    </div>
                    <input
                      className="form-control-login"
                      name="password"
                      type="tel"
                      placeholder="Enter OTP"
                      onChange={(e) => settwofactor_code(e.target.value)}
                    
                    />
                  </div>
                </div>
              )}
              {!otp && (
                <button
                  className="Login-button cxy mt-4"
                  onClick={(e) => handleClick(e)}
                  disabled={isButtonDisabled}
                >
                  {" "}
                  Continue
                </button>
              )}
              {otp && (
                <button
                  className="Login-button cxy mt-4 "
                  onClick={(e) => varifyOtp(e)}
                >
                  Verify
                </button>
              )}
             </div>
            </div>
            <div className="login-footer">
              By continuing I agree that Khelohub Ludo Pvt. Ltd. may store and
              process my data in accordance with the{" "}
              <Link to="/term-condition">Terms of Use</Link>,{" "}
              <Link to="/PrivacyPolicy">Privacy Policy</Link> and that I am 18
              years or older. I am not playing from Assam, Odisha, Nagaland,
              Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
