import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import { HiOutlineLogout } from "react-icons/hi";

const Profile1 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);
  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const [refcode, setRefcode] = useState();
  const history = useHistory();

  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        localStorage.removeItem("token");
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {});
  };

  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.Name);
        setCode(res.data.referral);
        setHolder_name(res.data.holder_name);
        setAccount_number(res.data.account_number);
        setIfsc_code(res.data.ifsc_code);
        setUpi_id(res.data.upi_id);
        setRefcode(res.data.referral_code);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [Name, setName] = useState();

  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Name,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };

  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holder_name,
          account_number,
          ifsc_code,
          upi_id,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (refcode == referral) {
      Swal.fire({
        title: "Can't use own referral code",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      const data = await axios
        .patch(
          baseUrl + `user/edit`,
          {
            referral,
          },
          { headers }
        )
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            UserALL();
            let message;
            message = res.data.msg;
            if (!res.data.msg) {
              message = "Invalid referral Code";
            }
            Swal.fire({
              title: message,
              icon: res.data.submit ? "success" : "error",
              confirmButtonText: "OK",
            });
          }
        });
    }
  };

  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);
      await fetch("https://kycseed.khelohub.com/users/me/avatar", {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }
  };

  useEffect(() => {
    UserALL();
  }, []);

  return (
    <>
      <Header user={profile} />
      <div className="profile">
        <div className="leftContainer">
          <div className="Inner_profile allpage_padding">
            <div className="">
              <div className={`${css.center_xy}`}>
                <div className="profile_page w-100">
                  <div
                    className="profile_div_main_box"
                   
                  >
                    <div className="profile_details ">
                      <h3>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 448 512"
                          className="card_icons"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
                        </svg>{" "}
                        {profile && profile.Name}
                        <img
                          className={`ml-2`}
                          width="20px"
                          // src={process.env.PUBLIC_URL + "/Images/draw.png"}
                          src={process.env.PUBLIC_URL + "/Images/icon-edit.jpg"}
                          alt=""
                          onClick={() => {
                            setPortcss(css.inactive_tab);
                            setPortcss1(css.active_tab);
                            
                          }}
                        />
                      </h3>

                      <div
                        className={`${portcss} font-weight-bold mb-1 text-white position-relative`}
                      >
                        <div
                          className="position-absolute"
                          style={{
                            top: "15px",
                            left: "25px",
                            fontSize: "25px",
                          }}
                        ></div>
                      </div>
                      <h3>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          className="card_icons"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z" />
                        </svg>{" "}
                        {profile && profile.Phone}
                      </h3>
                      <a
                        className="d-flex align-items-center  email_verified"
                        href=""
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="card_icons mb-0"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>{" "}
                        <div className="Profile_mytext__ZlZJy">
                          <div className="email_input">Email Verification</div>
                        </div>
                        <img
                          className="ml-2"
                          width="20px"
                          src="/Images/icon-edit.jpg"
                          alt=""
                        />
                      </a>
                      <div className="Profile_MuiInputBase_root__6qIew Profile_MuiInput_root__26rBM Profile_MuiInput_underline__P6SZ9 undefined undefined" />
                    </div>
                    <div>
                <label>
                  <input
                    className="d-none"
                    type="file"
                    onChange={(e) => Result(e.target.files[0])}
                    accept="image/*"
                    required
                  />
                  <picture>
                    {profile && profile.avatar ? (
                      <img
                        height="80px"
                        width="80px"
                        src={`https://kycseed.khelohub.com/${profile && profile.avatar}`}
                        alt=""
                        style={{ borderRadius: "50px" }}
                      />
                    ) : (
                      <img
                        height="80px"
                        width="80px"
                        src={process.env.PUBLIC_URL + `/user.png`}
                        alt=""
                        style={{ borderRadius: "50px" }}
                      />
                    )}
                  </picture>
                </label>
              </div>
                    <div>
                      {/* <div className="edit_profile">
                        <img src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"} width="20px" />
                        <span>Edit Profile</span>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className={`text-bold my-3 ${portcss1}`}>
              <div
                className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                style={{ verticalAlign: "bottom" }}
              >
                <div
                  className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                >
                  <input
                    aria-invalid="false"
                    type="text"
                    maxLength={"20"}
                    className={`${css.MuiInputBase_input} ${css.MuiInput_input}`}
                    placeholder="Enter Username"
                    value={Name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <img
                className="ml-2"
                width="20px"
                src={
                  process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"
                }
                alt=""
                onClick={() => {
                  setPortcss(css.active_tab);
                  setPortcss1(css.inactive_tab);
                  UpdateProfile(Id);
                }}
              />
            </div>
              
              <div className="padding_10 w-100">
                <div className="w-100 p-1 d-flex align-items-center justify-content-between border_light rounded mb-3">
                  <div className="profile_btn_nw_desgn">
                    <a href="/wallet" className="btn">
                      <img
                        src={`${process.env.PUBLIC_URL}/Images/global-cash-won-green-circular.png`}
                        className="mr-1"
                        alt="wallet"
                        width={"22px"}
                      />
                      Wallet
                    </a>
                  </div>

                  <div className="profile_btn_nw_desgn">
                    <Link
                      className={`d-flex align-items-center boxes btn`}
                      to={
                        profile && profile.verified === `unverified`
                          ? `/Kyc2`
                          : `/Profile`
                      }
                    >
                      <div
                        style={{ fontSize: "1rem" }}
                        className={`ml-2 ${css.mytext} text-white font-weight-bold`}
                      >
                        {profile && profile.verified === `unverified`
                          ? "KYC Verification"
                          : profile && profile.verified === "pending"
                          ? "In Process"
                          : profile && profile.verified === "verified"
                          ? "Completed Kyc ✅"
                          : "Completed Kyc ✅"}{" "}
                      </div>
                    </Link>{" "}
                  </div>
                </div>
                {profile && profile.referral == null && (
                   <>
                <div className="Complete_Profile_box">
                  <div className="media-bx d-flex align-items-center">
                    <img  src={`${process.env.PUBLIC_URL}/Images/referEarn.png`} className="w-10" alt="" />
                   
                    
                   
                    <div className="w-100">
                      <h2 className="profile_headings text-white">
                        Use Refer Code
                      </h2>
                      <div className=" Profile_position_relative__ZdBhv">
                        <div className="user_reder_code_box">
                         
                             

                              <div className="details refer_input profileinput">
                                <div className="d-flex align-items-center">
                                  <picture>
                                    <img
                                      height="24px"
                                      width="24px"
                                      src={`${process.env.PUBLIC_URL}/Images/Header/referEarn.png`}
                                      alt=""
                                      className="snip-img bg-white rounded"
                                    />
                                  </picture>

                                  <div className="pl-2">
                                    <div
                                      className={`inputtextplace ${css.text_uppercase} ${css.moneyBox_header}`}
                                      
                                    ></div>

                                    <input
                                      type="text"
                                      className={`inputtextplace ${css.MuiInputBase_input}`}
                                      aria-describedby="basic-addon1"
                                      value={referral}
                                      placeholder="Enter Refer Code"
                                      onChange={(e) => setCode(e.target.value)}
                                    />

                                    <Link
                                      style={{ right: "10px", bottom: "10px" }}
                                      className="ml-2 position-absolute"
                                      onClick={update_RefCode}
                                    >
                                      {/* <img
                                        width="20px"
                                        src={`${process.env.PUBLIC_URL}/Images/checked.png`}
                                        alt=""
                                      /> */}
                                      <img
                                        width="20px"
                                        className="ml-2"
                                        src={`${process.env.PUBLIC_URL}/Images/checked.png`}
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                          
                        </div>
                      </div>
                    </div>

                   
                  </div>
                </div>
                </>
            )}
              </div>
             
              </div>
            </div>

            <div className="padding_10 profile_info_small_box_main_row">
              <div className="row rows justify-content-center">
                <div class="col-12 col-sm-6 col-md-6">
                  <div
                    className={`profile_info_small_box_main d-flex   ${css.position_relative}`}
                  >
                    <div className="profile_info_small_box_inner boxprofilesize bg_1">
                      <picture>
                        <img
                          height="40px"
                          width="40px"
                          src={process.env.PUBLIC_URL + "/Images/rupee.png"}
                          alt=""
                          className="snip-img mr-1"
                        />
                      </picture>
                    </div>
                    <div className="text-left ml-2">
                      <h3
                        className={`${css.text_uppercase} ${css.moneyBox_header}`}
                        style={{}}
                      >
                        Coin Won
                      </h3>
                      <h4>
                        <span className={css.moneyBox_text}>
                          {profile && profile.wonAmount}
                        </span>
                      </h4>
                      <span className={css.thin_divider_x}></span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                  <div className="profile_info_small_box_main d-flex ">
                    <div className={`profile_info_small_box_inner boxprofilesize bg_1 ${css.position_relative}`} >
                        <picture>
                          <img
                            height="32px"
                            width="32px"
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/referral-signup-bonus-new.png"
                            }
                            alt=""
                            className="snip-img mr-1"
                          />
                        </picture>
                      </div>
                      <div className="text-left ml-2">
                        <h3
                          className={`${css.text_uppercase} ${css.moneyBox_header}`}
                          style={{}}
                        >
                          Referral Earning
                        </h3>

                        <h4 className={css.moneyBox_text}>
                          {profile && profile.referral_earning}
                        </h4>
                      </div>
                    </div>
                  </div>
                <div class="col-12 col-sm-12">
                  <div className="profile_info_small_box_main d-flex">
                    <div
                      className={`profile_info_small_box_inner d-flex  align-items-center bg_1 ${css.position_relative}`}
                    >
                      <div className="boxprofilesize">
                          <picture>
                            <img
                              height="32px"
                              width="36px"
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/battleplayed.png"
                              }
                              alt=""
                              className="mr-1"
                            />
                          </picture>
                        </div>
                        <div className="text-left ml-2">
                          <h3
                            className={`${css.text_uppercase} ${css.moneyBox_header}`}
                          >
                            Battle Played
                          </h3>
                          <h4 className={css.moneyBox_text}>
                            {total && total}
                          </h4>
                        </div>
                     
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          <div className="p-1 snipcss-A1eLC snip-div">
            <Link
              to="/login"
              className={`${css.center_xy}  text-uppercase py-4 font-weight-bolder text-muted`}
              onClick={(e) => logout(e)}

            >
              <span className="text-white mt-2 log_out">
                Log Out <HiOutlineLogout className="" />
              </span>
            </Link>
          </div>
          </div>
        </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
      <div className={css.kyc_select} id="profileModal">
        <div className={css.overlay} />
        <div
          className={`${css.box}`}
          style={{
            bottom: "0px",
            position: "absolute",
          }}
        >
          <div className={css.bg_white}>
            <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
              <picture>
                <img
                  height="80px"
                  width="80px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
              </picture>
              <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
            </div>
            <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
              <div className="row justify-content-between col-10 mx-auto">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                  alt=""
                />
              </div>
              <div className="row justify-content-between col-10 mx-auto mt-3">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile1;
